<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <basic-dialog
        v-model="createCaseDialog"
        title="$l.app.warning"
        save-button
        save-button-label="$l.app.confirm"
        @close-dialog="createCaseDialog=false"
        @save-dialog="createCase()"
      >
        <v-container>
          <app-api-call
            :call-result="callResult"
          />
          {{ $i18n.t('$l.screening.confirmCaseCreation') + ' ' }}
          <span
            class="font-weight-medium"
          >{{ apiCheck.subject }}</span>
        </v-container>
      </basic-dialog>
      <v-icon
        v-bind="attrs"
        class="mr-2"
        v-on="on"
        @click.stop.prevent="createCaseDialog = true"
      >
        mdi-folder-plus
      </v-icon>
    </template>
    <span>{{ $i18n.t('$l.paths.createCase') }}</span>
  </v-tooltip>
</template>

<script>
import caseMethods from '@/methods/case.js'

export default {
  name: 'CreateCaseAction',
  props: {
    apiCheck: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      createCaseDialog: false,
      callResult: { finished: true, authorized: true, error: null, info: null }
    }
  },
  methods: {
    createCase () {
      if (this.callResult.finished) {
        this.callResult.finished = false
        this.callResult.error = null
        let apiCHechData = JSON.parse(this.apiCheck.data)
        let createCase = {
          caseType: 'PhysicalPersonCase',
          subject: this.apiCheck.subject,
          birthDate: apiCHechData.subject.birthDate,
          idCardNumber: apiCHechData.subject.cardId,
          idCardType: 'IDCARD'
        }
        let newCase = caseMethods.createCase(createCase, this.$utils.participantDefault)
        this.$xapi.put('case/create', newCase)
          .then(response => {
            if (response.data.type === 'TransactionCase') {
              this.$router.push({ name: 'ProcessCase', params: { caseId: response.data.caseId } })
            } else {
              this.$router.push({ name: 'ProcessCasePerson', params: { caseId: response.data.caseId } })
            }
          })
          .catch(e => {
            this.callResult.error = e.response.localizedMessage
          })
          .finally(() => {
            this.callResult.finished = true
          })
      }
    }
  }
}
</script>
