var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{staticClass:"info2 white--text",attrs:{"text":"","loading":_vm.exportInProgress},on:{"click":function($event){_vm.confirmationDialog=true}}},[_vm._v(" "+_vm._s(_vm.$i18n.t('$l.api.reportButton'))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-file-excel ")])],1),_c('v-snackbar',{attrs:{"color":"error"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$i18n.t('$l.app.close'))+" ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")]),_c('basic-dialog',{attrs:{"title":"$l.api.reportTitle","save-button":"","save-button-label":"$l.app.confirm"},on:{"close-dialog":function($event){_vm.confirmationDialog=false},"save-dialog":function($event){return _vm.downloadReport()}},model:{value:(_vm.confirmationDialog),callback:function ($$v) {_vm.confirmationDialog=$$v},expression:"confirmationDialog"}},[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_vm._v(" "+_vm._s(_vm.$i18n.t('$l.api.reportText'))+" ")]),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-menu',{ref:"dateFromMenu",attrs:{"close-on-content-click":false,"return-value":_vm.dateFrom,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dateFrom=$event},"update:return-value":function($event){_vm.dateFrom=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ma-3",attrs:{"label":_vm.$i18n.t('$l.apicheck.dateFrom'),"prepend-icon":"mdi-calendar","readonly":"","dense":""},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateFromMenu),callback:function ($$v) {_vm.dateFromMenu=$$v},expression:"dateFromMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","locale":_vm.$i18n.locale},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.dateFromMenuCancel}},[_vm._v(" "+_vm._s(_vm.$t('$l.app.cancel'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.dateFromMenuOk}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-menu',{ref:"dateToMenu",attrs:{"close-on-content-click":false,"return-value":_vm.dateTo,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dateTo=$event},"update:return-value":function($event){_vm.dateTo=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ma-3",attrs:{"label":_vm.$i18n.t('$l.apicheck.dateTo'),"prepend-icon":"mdi-calendar","readonly":"","dense":""},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateToMenu),callback:function ($$v) {_vm.dateToMenu=$$v},expression:"dateToMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","locale":_vm.$i18n.locale},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.dateToMenuCancel}},[_vm._v(" "+_vm._s(_vm.$t('$l.app.cancel'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.dateToMenuOk}},[_vm._v(" OK ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }