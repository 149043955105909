<template>
  <v-card
    outlined
    class="mb-3 mt-3 greyee pa-2"
  >
    <v-card-subtitle
      class="font-weight-medium ma-0 pa-0 body-1"
    >
      {{ match.primaryName }}
    </v-card-subtitle>

    <v-card-subtitle
      class="font-weight-medium ma-0 pa-0 body-2"
    >
      {{ $i18n.t('$l.api.detailSourceDbTitle') + ' ' + match.sourceDb }}
    </v-card-subtitle>

    <v-card-subtitle
      class="font-weight-medium ma-0 pa-0 body-2"
    >
      {{ $i18n.t('$l.api.detailBirthTitle') }}
      <v-card-subtitle
        v-for="(bth, idx) in match.birth"
        :key="idx"
        class="font-weight-medium ma-0 pa-0 body-2 ml-2"
      >
        {{ bth.date }}
      </v-card-subtitle>
    </v-card-subtitle>

    <v-card-subtitle
      class="font-weight-medium ma-0 pa-0 body-2"
    >
      {{ $i18n.t('$l.api.detailNamesTitle') }}
      <v-card-subtitle
        v-for="(name, idx) in match.name"
        :key="idx"
        class="font-weight-medium ma-0 pa-0 body-2 ml-2"
      >
        {{ name.wholename }}
      </v-card-subtitle>
    </v-card-subtitle>

    <v-card-subtitle
      class="font-weight-medium ma-0 pa-0 body-2"
    >
      {{ $i18n.t('$l.api.detailMembershipTitle') }}
      <v-card-subtitle
        v-for="(memb, idx) in match.membership"
        :key="idx"
        class="font-weight-medium ma-0 pa-0 body-2 ml-2"
      >
        {{ ((memb.function && memb.function !== 'member') ? memb.function : '') + " " + (memb.name ? memb.name : '') }}
      </v-card-subtitle>
    </v-card-subtitle>

    <v-card-subtitle
      class="font-weight-medium ma-0 pa-0 body-2"
    >
      {{ $i18n.t('$l.api.detailCitizenshipTitle') }}
      <v-card-subtitle
        v-for="(ctz, idx) in match.citizenshipCountry"
        :key="idx"
        class="font-weight-medium ma-0 pa-0 body-2 ml-2"
      >
        {{ ctz }}
      </v-card-subtitle>
    </v-card-subtitle>

    <v-card-subtitle
      class="font-weight-medium ma-0 pa-0 body-2"
    >
      {{ $i18n.t('$l.api.detailNationalityTitle') }}
      <v-card-subtitle
        v-for="(nat, idx) in match.nationalityCountry"
        :key="idx"
        class="font-weight-medium ma-0 pa-0 body-2 ml-2"
      >
        {{ nat }}
      </v-card-subtitle>
    </v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  name: 'ApiCheckMatchDetail',
  props: {
    match: {
      type: Object,
      required: true
    }
  }
}
</script>
