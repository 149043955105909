<template>
  <v-container
    fluid
    class="white ma-0 pa-0"
  >
    <CheckListHeader
      v-if="!$store.state.user.isAuthenticated"
      :call-result="callResult"
      @openCheckListAuthorizationDialog="openCheckListAuthorizationDialog"
      @apiCheckListLogout="apiCheckListLogout"
      @reloadDefaultCheckListData="reloadDefaultCheckListData"
    />

    <v-card-title
      v-if="!$store.state.user.isAuthenticated"
      class="pa-2 ma-0"
    >
      {{ $i18n.t('$l.api.lastHundredChecks') }}
    </v-card-title>

    <app-api-call
      class="ma-0 pa-0 mb-3"
      :call-result="callResult"
    />

    <CheckListTable
      v-if="callResult.finished"
      :api-check-items="apiCheckItems"
      :call-result="callResult"
      :request-params="requestParams"
      :total-items="totalItems"
      :categories="categories"
      @openCheckDetailDialog="openCheckDetailDialog"
      @requestParamsChanged="requestParamsChanged"
    />

    <basic-dialog
      v-if="callResult.finished"
      v-model="checkDetailDialog"
      title="$l.api.checkDetailDialog"
      @close-dialog="closeCheckDetailDialog()"
    >
      <app-api-call
        class="ma-0 pa-0 mb-3"
        :call-result="callResultDialog"
      />
      <div
        v-if="currentCheckDetail"
        class="ma-3 mt-0"
      >
        <div v-if="JSON.parse(currentCheckDetail.data).idCardCheck==1">
          <v-alert
            dense
            border="left"
            type="error"
            color="error"
          >
            <strong>{{ $i18n.t('$l.api.lostIdCardWarning') }}</strong>
          </v-alert>
        </div>
        <div v-if="JSON.parse(currentCheckDetail.data).countryRiskLevel==2">
          <v-alert
            dense
            border="left"
            type="error"
            color="error"
          >
            <strong>{{ $i18n.t('$l.api.countryRiskHighWarning') }}</strong>
          </v-alert>
        </div>
        <div>{{ currentCheckDetail.subject }}</div>
        <div>{{ JSON.parse(currentCheckDetail.data).subject.birthDate }}</div>

        <v-divider class="my-1" />

        <div>{{ $i18n.t('$l.api.evaluation') }}</div>

        <div
          class="d-flex justify-start mt-3 mb-6"
        >
          <v-btn
            text
            class="success mr-3"
            small
            :disabled="currentCheckDetail.checkStatus === 'NEW_OK' || currentCheckDetail.checkStatus === 'NEW_FAILED' || savingCheckStatus"
            :loading="savingCheckStatus"
            @click="currentCheckDetail.checkStatus = 'CONFIRMED_OK'"
          >
            {{ $i18n.t('$l.api.notMatch') }}
            <v-icon
              v-if="currentCheckDetail.checkStatus === 'CONFIRMED_OK'"
              right
              dark
            >
              mdi-check
            </v-icon>
          </v-btn>

          <v-btn
            text
            class="error"
            small
            :disabled="currentCheckDetail.checkStatus === 'NEW_OK' || currentCheckDetail.checkStatus === 'NEW_FAILED' || savingCheckStatus"
            :loading="savingCheckStatus"
            @click="currentCheckDetail.checkStatus = 'CONFIRMED_MATCH'"
          >
            {{ $i18n.t('$l.api.confirmMatch') }}
            <v-icon
              v-if="currentCheckDetail.checkStatus === 'CONFIRMED_MATCH'"
              right
              dark
            >
              mdi-check
            </v-icon>
          </v-btn>
        </div>
        <v-combobox
          v-if="isAzah"
          v-model="affiliateVisitPurposeSelect"
          :v-show="false"
          :items="affiliateVisitPurposeItems"
          :label="$i18n.t('$l.api.affiliateVisitPurposeInput')"
          :error-messages="($v.affiliateVisitPurposeSelect.$dirty && !$v.affiliateVisitPurposeSelect.required) ? $i18n.t('$l.app.required') : ''"
          dense
          outlined
        />

        <v-text-field
          v-if="isAzah"
          v-model="currentCheckDetail.affiliateVisitSpentAmount"
          outlined
          dense
          maxlength="6"
          counter
          type="number"
          class="mt-3"
          :label="$i18n.t('$l.api.affiliateSpentAmountInput')"
          :error-messages="($v.currentCheckDetail.affiliateVisitSpentAmount.$dirty && !$v.currentCheckDetail.affiliateVisitSpentAmount.betweenValue) ? 'Input number >= 0 and <= 500000' : ''"
          @input="$v.currentCheckDetail.affiliateVisitSpentAmount.$touch()"
          @blur="$v.currentCheckDetail.affiliateVisitSpentAmount.$touch()"
        />

        <div
          class="d-flex justify-end mt-1 mb-3"
        >
          <v-btn
            text
            class="info"
            small
            :loading="savingCheckStatus"
            @click="setCheckStatus('CONFIRMED_OK')"
          >
            {{ $i18n.t('$l.app.save') }}
          </v-btn>
        </div>

        <v-divider class="my-1" />

        {{ $i18n.t('$l.api.lastEvaluation') + $utils.formatDate(currentCheckDetail.lastModified) }}

        <v-divider class="my-1" />

        <div
          v-if="currentCheckDetail.checkStatus === 'NEW_OK'"
        >
          {{ $i18n.t('$l.api.new_ok_info') }}

          <v-divider class="my-1" />
        </div>

        <div
          v-if="currentCheckDetail.checkStatus === 'NEW_MATCH' || currentCheckDetail.checkStatus === 'CONFIRMED_OK' || currentCheckDetail.checkStatus === 'CONFIRMED_MATCH' || currentCheckDetail.checkStatus === 'NEW_UNSEARCHABLE'"
        >
          {{ $i18n.t('$l.api.new_match_info') }}

          <v-divider class="my-1" />

          <div
            v-for="(p, idx) in JSON.parse(currentCheckDetail.data).pepMatches"
            :key="`p${idx}`"
          >
            <CheckMatchDetail :match="p" />
          </div>

          <div
            v-for="(s, idx) in JSON.parse(currentCheckDetail.data).sanctionMatches"
            :key="`s${idx}`"
          >
            <CheckMatchDetail :match="s" />
          </div>
        </div>
      </div>
    </basic-dialog>

    <v-card
      v-show="checkListDataRequestDialog"
      class="pa-2"
      elevation="6"
      width="400"
      outlined
    >
      <v-text-field
        id="checkListUserName"
        v-model="loadCheckListRequest.apiUser"
        type="text"
        autocomplete="new-password"
        :label="$i18n.t('$l.api.apiUserName')"
        :error-messages="($v.loadCheckListRequest.apiUser.$dirty && !$v.loadCheckListRequest.apiUser.required) ? $i18n.t('$l.app.required') : ''"
        @input="$v.loadCheckListRequest.apiUser.$touch()"
        @blur="$v.loadCheckListRequest.apiUser.$touch()"
      />
      <v-text-field
        id="checkListPassword"
        v-model="loadCheckListRequest.apiUserPassword"
        type="password"
        autocomplete="new-password"
        :label="$i18n.t('$l.api.apiUserPassword')"
        :error-messages="($v.loadCheckListRequest.apiUserPassword.$dirty && !$v.loadCheckListRequest.apiUserPassword.required) ? $i18n.t('$l.app.required') : ''"
        @input="$v.loadCheckListRequest.apiUserPassword.$touch()"
        @blur="$v.loadCheckListRequest.apiUserPassword.$touch()"
        @keydown.enter="saveCheckListAuthorizationDialog()"
      />
      <v-card-actions>
        <v-spacer />
        <v-btn
          :label="'$l.app.cancel' | translate"
          text
          @click="closeCheckListAuthorizationDialog()"
        >
          {{ '$l.app.cancel' | translate }}
        </v-btn>
        <v-btn
          id="checkListLogin"
          class="ma-0 pa-0"
          text
          :loading="!callResult.finished"
          @click="saveCheckListAuthorizationDialog()"
        >
          {{ '$l.api.login' | translate }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import CheckListHeader from './CheckListHeader.vue'
import CheckListTable from './CheckListTable.vue'
import CheckMatchDetail from './CheckMatchDetail.vue'

import { required, between } from 'vuelidate/lib/validators'

export default {
  name: 'CheckList',
  components: {
    CheckListHeader,
    CheckListTable,
    CheckMatchDetail
  },
  data () {
    return {
      categories: null,
      callResult: { finished: false, authorized: false, error: null, info: null },
      callResultDialog: { finished: true, authorized: false, error: null, info: null },
      apiCheckItems: [],
      loadCheckListRequest: {
        apiUser: '',
        apiUserPassword: '',
        apiUserRefreshToken: null
      },
      requestParams: {
        page: 1,
        itemsPerPage: 100,
        sortBy: [],
        sortDesc: [],
        apiUser: '',
        apiAffiliate: '',
        checkStatus: '',
        subjectSubstring: '',
        dateFrom: '',
        dateTo: '',
        pageOptions: {},
        affiliateVisitPurpose: ''
      },
      totalItems: 0,
      checkDetailDialog: false,
      currentCheckDetail: null,
      checkListDataRequestDialog: false,
      savingCheckStatus: false,
      affiliateVisitPurposeSelect: null,
      affiliateVisitPurposeOld: null,
      affiliateVisitSpentAmountOld: null,
      checkStatusOld: null,
      affiliateVisitPurposeItems: [
        { text: this.$i18n.t('$l.api.visitReason.SRVC'),
          value: 'SRVC'
        },
        { text: this.$i18n.t('$l.api.visitReason.BAR'),
          value: 'BAR'
        },
        { text: this.$i18n.t('$l.api.visitReason.GAME'),
          value: 'GAME'
        }
      ]
    }
  },
  computed: {
    isAzah () {
      if (this.categories) { return this.categories.includes('AZAH') } else return false
    }
  },
  validations: {
    affiliateVisitPurposeSelect: { required },
    currentCheckDetail: {
      affiliateVisitSpentAmount: { betweenValue: between(0, 500000) }
    },
    loadCheckListRequest: {
      apiUser: { required },
      apiUserPassword: { required }
    }
  },
  created () {
    this.loadCheckListData()
  },
  methods: {
    loadCheckListData () {
      this.callResult.finished = false
      this.callResult.error = null
      this.callResult.info = this.$i18n.t('$l.info.loadingData')
      this.loadCheckListRequest.apiUserRefreshToken = this.$cookies.get('apiUserRefreshToken')
      this.loadCheckListRequest.requestParams = this.requestParams
      const payload = JSON.parse(JSON.stringify(this.loadCheckListRequest))
      if (payload.requestParams.dateFrom !== '') {
        payload.requestParams.dateFrom = new Date(payload.requestParams.dateFrom + 'T00:00:00').toISOString()
      }
      if (payload.requestParams.dateTo !== '') {
        payload.requestParams.dateTo = new Date(payload.requestParams.dateTo + 'T00:00:00').toISOString()
      }
      this.$xapi.post('apiCheck/checkResult', payload)
        .then(r => {
          this.apiCheckItems = JSON.parse(r.data.data)
          this.apiCheckItems.forEach(i => {
            i.subject = JSON.parse(i.data).subject.subject
            i.checkStatusLoc = this.$i18n.t(this.$i18n.t(`$l.api.${i.checkStatus}`))
          })

          this.totalItems = r.data.totalItems
          this.$cookies.set('apiUserRefreshToken', r.data.apiUserRefreshToken, -1)
          this.callResult.authorized = true
          this.categories = r.data.categories
          this.closeCheckListAuthorizationDialog()
          this.loadCheckListRequest.apiUser = ''
          this.loadCheckListRequest.apiUserPassword = ''
          this.$v.$reset()
        })
        .catch(e => {
          this.apiCheckItems = []
          this.callResult.error = e.response.localizedMessage
          this.callResult.authorized = false
          if (e.response.status === 403) {
            this.callResult.error = this.$i18n.t('$l.api.userNotLoggedIn')
            this.$cookies.set('apiUserRefreshToken', null, -1)
          }
          this.categories = null
        })
        .finally(() => {
          this.callResult.info = null
          this.callResult.finished = true
        })
    },
    openCheckDetailDialog (item) {
      this.callResultDialog.error = null
      this.currentCheckDetail = JSON.parse(JSON.stringify(item))
      this.affiliateVisitPurposeItems.forEach(item => {
        if (item.value === this.currentCheckDetail.affiliateVisitPurpose) {
          this.affiliateVisitPurposeSelect = item
        }
      })
      this.affiliateVisitPurposeOld = this.currentCheckDetail.affiliateVisitPurpose ? this.currentCheckDetail.affiliateVisitPurpose : ''
      this.affiliateVisitSpentAmountOld = this.currentCheckDetail.affiliateVisitSpentAmount ? this.currentCheckDetail.affiliateVisitSpentAmount : ''
      this.checkStatusOld = this.currentCheckDetail.checkStatus ? this.currentCheckDetail.checkStatus : ''
      this.checkDetailDialog = true
    },
    closeCheckDetailDialog () {
      this.checkDetailDialog = false
      this.currentCheckDetail = null
      this.affiliateVisitPurposeSelect = null
      this.$v.$reset()
    },
    setCheckStatus () {
      this.$v.$touch()
      if (this.isAzah) {
        if (!this.$v.affiliateVisitPurposeSelect.required || !this.$v.currentCheckDetail.affiliateVisitSpentAmount.betweenValue) {
          return
        }
      }
      this.savingCheckStatus = true
      let statusSetObject = {}
      statusSetObject.checkStatus = this.currentCheckDetail.checkStatus
      statusSetObject.checkId = this.currentCheckDetail.id
      if (this.isAzah) {
        statusSetObject.affiliateVisitPurpose = this.affiliateVisitPurposeSelect.value
        statusSetObject.affiliateVisitSpentAmount = this.currentCheckDetail.affiliateVisitSpentAmount.toString()
        statusSetObject.affiliateVisitPurposeOld = this.affiliateVisitPurposeOld
        statusSetObject.affiliateVisitSpentAmountOld = this.affiliateVisitSpentAmountOld.toString()
      } else {
        statusSetObject.affiliateVisitPurpose = ''
        statusSetObject.affiliateVisitSpentAmount = '0'
        statusSetObject.affiliateVisitPurposeOld = ''
        statusSetObject.affiliateVisitSpentAmountOld = '0'
      }
      if (statusSetObject.affiliateVisitPurpose == null) {
        statusSetObject.affiliateVisitPurpose = ''
      }
      if (statusSetObject.affiliateVisitSpentAmount == null) {
        statusSetObject.affiliateVisitSpentAmount = 0
      }
      if (statusSetObject.affiliateVisitPurposeOld == null) {
        statusSetObject.affiliateVisitPurposeOld = ''
      }
      if (statusSetObject.affiliateVisitSpentAmountOld == null) {
        statusSetObject.affiliateVisitSpentAmountOld = 0
      }
      statusSetObject.checkStatusOld = this.checkStatusOld
      statusSetObject.apiUserRefreshToken = this.$cookies.get('apiUserRefreshToken')
      this.$xapi.post('apiCheck/setCheckStatus', statusSetObject)
        .then(r => {
          this.closeCheckDetailDialog()
          this.loadCheckListData()
        })
        .catch(e => {
          this.callResultDialog.error = e.response.localizedMessage
        })
        .finally(() => {
          this.savingCheckStatus = false
        })
    },
    openCheckListAuthorizationDialog () {
      this.checkListDataRequestDialog = true
    },
    closeCheckListAuthorizationDialog () {
      this.checkListDataRequestDialog = false
    },
    saveCheckListAuthorizationDialog () {
      this.$v.$touch()
      if (this.$v.loadCheckListRequest.apiUser.required && this.$v.loadCheckListRequest.apiUserPassword.required) {
        this.loadCheckListData()
      }
    },
    apiCheckListLogout () {
      this.$cookies.set('apiUserRefreshToken', null, -1)
      this.loadCheckListData()
      this.callResult.authorized = false
    },
    requestParamsChanged (eventData) {
      this.loadCheckListData()
    },
    reloadDefaultCheckListData () {
      if (this.requestParams.pageOptions.page !== 1) {
        // pageOptions is reactive and watched; data reloads on detected change
        this.requestParams.pageOptions.page = 1
      } else {
        // if no change, manually invoke data reloading
        this.loadCheckListData()
      }
    }
  }
}
</script>
