<template>
  <v-data-table
    class="ma-0 pa-0 okaml_cursor_pointer"
    dense
    :headers="computedHeaders"
    :items="apiCheckItems"
    :item-class="itemRowBackground"
    :mobile-breakpoint="0"
    :loading="!callResult.finished"
    :options.sync="requestParams.pageOptions"
    :server-items-length="totalItems"
    :footer-props="{
      itemsPerPageOptions:[!$store.state.user.isAuthenticated ? 100 : 100]
    }"
    @click:row="$emit('openCheckDetailDialog', $event)"
  >
    <template
      v-if="$store.state.user.isAuthenticated"
      v-slot:top
    >
      <CheckListFilterPanel
        :request-params="requestParams"
        :call-result="callResult"
        :categories="categories"
        @requestParamsChanged="$emit('requestParamsChanged')"
      />
    </template>
    <template v-slot:item.actions="{ item }">
      <CreateCaseAction
        :api-check="item"
      />
    </template>
    <template #[`item.created`]="{ item }">
      <span style="white-space: nowrap">{{ $utils.formatDate(item.created) }}</span>
    </template>

    <template #[`item.checkStatusLoc`]="{ item }">
      <div
        class="d-flex"
      >
        <v-chip
          dark
          small
          :color="getStatusColor(item.checkStatus)"
        >
          {{ item.checkStatusLoc }}
        </v-chip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="JSON.parse(item.data).idCardCheck==1"
              v-bind="attrs"
              class="text--red"
              v-on="on"
            >
              mdi-alert-circle-outline
            </v-icon>
          </template>
          <span>
            {{ $i18n.t('$l.api.lostIdCardTooltip') }}
          </span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="JSON.parse(item.data).countryRiskLevel==2"
              v-bind="attrs"
              class="text--red"
              v-on="on"
            >
              mdi-alert-circle-outline
            </v-icon>
          </template>
          <span>
            {{ $i18n.t('$l.api.countryRiskHigh') }}
          </span>
        </v-tooltip>
      </div>
    </template>
    <template v-slot:[`item.affiliateVisitPurpose`]="{ item }">
      {{ !item.affiliateVisitPurpose ? "" : $i18n.t(`$l.api.visitReason.${item.affiliateVisitPurpose}`) }}
    </template>
  </v-data-table>
</template>

<script>
import CreateCaseAction from '@/components/api/CreateCaseAction.vue'
import CheckListFilterPanel from '@/components/api/CheckListFilterPanel.vue'

export default {
  name: 'CheckListTable',
  components: { CreateCaseAction, CheckListFilterPanel },
  props: {
    callResult: {
      type: Object,
      required: true
    },
    apiCheckItems: {
      type: Array,
      required: true
    },
    requestParams: {
      type: Object,
      required: true
    },
    totalItems: {
      type: Number,
      required: true
    },
    categories: {
      type: Array,
      default: () => [],
      required: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
    headers () {
      let headerArray = [
        { text: this.$t('$l.api.apiUserAffiliate'), value: 'apiAffiliate', sortable: this.$store.state.user.isAuthenticated },
        { text: this.$t('$l.case.created'), value: 'created', sortable: this.$store.state.user.isAuthenticated },
        { text: this.$t('$l.api.subject'), value: 'subject', sortable: false },
        { text: this.$t('$l.api.affiliateVisitSpentAmount'), value: 'affiliateVisitSpentAmount', sortable: false },
        { text: this.$t('$l.api.affiliateVisitPurpose'), value: 'affiliateVisitPurpose', sortable: false },
        { text: this.$t('$l.api.checkStatus'), value: 'checkStatusLoc', sortable: false }
      ]
      if (this.$store.state.user.isAuthenticated) {
        headerArray.push({ text: this.$t('$l.app.actions'), value: 'actions', sortable: false })
      }
      return headerArray
    },
    isAzah () {
      if (this.categories) { return this.categories.includes('AZAH') } else return false
    },
    colMd () {
      if (this.isAzah) {
        return '2'
      } else {
        return '3'
      }
    },
    computedHeaders () {
      return this.headers.filter(this.filterHeaders)
    }
  },
  watch: {
    'requestParams.pageOptions' (newValue, oldValue) {
      if (oldValue.page !== newValue.page) {
        this.pageOptionsChanged()
      }
      if (oldValue.sortBy !== newValue.sortBy) {
        this.pageOptionsChanged()
      }
    }
  },
  methods: {
    itemRowBackground: function (item) {
      let data = JSON.parse(item.data)
      let result = ''
      if (data.idCardCheck === 1) {
        result = 'error'
      } else if (data.countryRiskLevel === 2) {
        result = 'error'
      }
      return result
    },
    filterHeaders (header) {
      if (this.isAzah) {
        return true
      } else {
        return header.value !== 'affiliateVisitSpentAmount' && header.value !== 'affiliateVisitPurpose'
      }
    },
    pageOptionsChanged () {
      this.requestParams.page = this.requestParams.pageOptions.page
      this.requestParams.sortBy = this.requestParams.pageOptions.sortBy
      this.requestParams.sortDesc = this.requestParams.pageOptions.sortDesc
      this.$emit('requestParamsChanged')
    },
    getStatusColor (status) {
      if (status === 'NEW_OK') return 'success'
      if (status === 'NEW_MATCH') return 'warning'
      if (status === 'NEW_UNSEARCHABLE') return 'warning'
      if (status === 'NEW_FAILED') return 'info'
      if (status === 'CONFIRMED_OK') return 'success'
      if (status === 'CONFIRMED_MATCH') return 'error'
      else return 'error'
    },
    // function not used, but kept for documenting proper
    // :search="requestParams.search"
    // :custom-filter="apiCheckItemsFilter"
    apiCheckItemsFilter (value, search, item) {
      if (value === item.subject && value.toUpperCase().includes(search.toUpperCase())) {
        return true
      } else {
        if (value === item.created && this.$utils.formatDate(value).replace(/ /g, '').includes(search.replace(/ /g, ''))) {
          return true
        } else {
          return false
        }
      }
    },
    // function not used, but kept for documenting proper
    // :custom-sort="apiCheckItemsSort"
    apiCheckItemsSort (items, index, isDesc) {
      let desc = isDesc[0]
      let idx = index[0]
      if (this.$store.state.user.isAuthenticated) {
        items.sort((a, b) => {
          let first = desc ? a[idx] : b[idx]
          let second = desc ? b[idx] : a[idx]
          return first > second ? -1 : 1
        })
      }
      return items
    }
  }
}
</script>
