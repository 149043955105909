var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"ma-0 pa-0 okaml_cursor_pointer",attrs:{"dense":"","headers":_vm.computedHeaders,"items":_vm.apiCheckItems,"item-class":_vm.itemRowBackground,"mobile-breakpoint":0,"loading":!_vm.callResult.finished,"options":_vm.requestParams.pageOptions,"server-items-length":_vm.totalItems,"footer-props":{
    itemsPerPageOptions:[!_vm.$store.state.user.isAuthenticated ? 100 : 100]
  }},on:{"update:options":function($event){return _vm.$set(_vm.requestParams, "pageOptions", $event)},"click:row":function($event){return _vm.$emit('openCheckDetailDialog', $event)}},scopedSlots:_vm._u([(_vm.$store.state.user.isAuthenticated)?{key:"top",fn:function(){return [_c('CheckListFilterPanel',{attrs:{"request-params":_vm.requestParams,"call-result":_vm.callResult,"categories":_vm.categories},on:{"requestParamsChanged":function($event){return _vm.$emit('requestParamsChanged')}}})]},proxy:true}:null,{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('CreateCaseAction',{attrs:{"api-check":item}})]}},{key:"item.created",fn:function(ref){
  var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$utils.formatDate(item.created)))])]}},{key:"item.checkStatusLoc",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-chip',{attrs:{"dark":"","small":"","color":_vm.getStatusColor(item.checkStatus)}},[_vm._v(" "+_vm._s(item.checkStatusLoc)+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [(JSON.parse(item.data).idCardCheck==1)?_c('v-icon',_vm._g(_vm._b({staticClass:"text--red"},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle-outline ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$i18n.t('$l.api.lostIdCardTooltip'))+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [(JSON.parse(item.data).countryRiskLevel==2)?_c('v-icon',_vm._g(_vm._b({staticClass:"text--red"},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle-outline ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$i18n.t('$l.api.countryRiskHigh'))+" ")])])],1)]}},{key:"item.affiliateVisitPurpose",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(!item.affiliateVisitPurpose ? "" : _vm.$i18n.t(("$l.api.visitReason." + (item.affiliateVisitPurpose))))+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }