<template>
  <v-card
    flat
    color="greyee mb-3"
  >
    <v-card-actions
      class="ma-0 pa-3 pb-1"
    >
      <v-row
        class="ma-0 pa-0"
      >
        <v-btn
          v-if="!callResult.authorized"
          text
          dark
          class="info1 mr-2 mb-2"
          @click="$emit('openCheckListAuthorizationDialog')"
        >
          {{ $i18n.t('$l.api.login') }}
        </v-btn>

        <v-btn
          v-if="callResult.authorized"
          text
          dark
          class="secondary mr-2 mb-2"
          @click="$emit('apiCheckListLogout')"
        >
          {{ $i18n.t('$l.api.logout') }}
        </v-btn>

        <v-btn
          text
          class="primary mr-2 mb-2"
          @click="$emit('reloadDefaultCheckListData')"
        >
          {{ $i18n.t('$l.api.refresh') }}
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'CheckListHeader',
  props: {
    callResult: {
      type: Object,
      required: true
    }
  }
}
</script>
