<template>
  <v-card
    outlined
    class="mb-1"
  >
    <v-row dense>
      <v-col cols="3">
        <v-card-subtitle class="ma-0 pa-0 pa-3 body-1 font-weight-medium">
          {{ $i18n.t('$l.app.filter') }}
        </v-card-subtitle>
      </v-col>
      <v-col align="right">
        <v-btn
          text
          rounded
          class="info2 white--text ma-1"
          @click="resetFilterAndSearchOptions()"
        >
          {{ $i18n.t('$l.api.refresh') }}
          <v-icon small>
            mdi-reload
          </v-icon>
        </v-btn>
        <DownloadReport
          class="d-inline ma-1"
        />
        <DownloadExcelCheckList
          class="d-inline ma-1"
          :request-params="requestParams"
          :is-azah="isAzah"
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col
        cols="12"
        sm="12"
        md="2"
      >
        <v-combobox
          v-model="requestParams.apiAffiliate"
          class="ma-3"
          dense
          clearable
          :disabled="apiUserAffiliatesLoading"
          :loading="apiUserAffiliatesLoading"
          :items="apiUserAffiliateItems"
          :label="$i18n.t('$l.api.apiUserAffiliate')"
          @change="requestParamsChanged"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="2"
      >
        <v-menu
          ref="dateFromMenu"
          v-model="dateFromMenu"
          :close-on-content-click="false"
          :return-value.sync="requestParams.dateFrom"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="requestParams.dateFrom"
              :label="$i18n.t('$l.apicheck.dateFrom')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              dense
              class="ma-3"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="requestParams.dateFrom"
            no-title
            scrollable
            :locale="$i18n.locale"
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="dateFromMenuCancel"
            >
              {{ $t('$l.app.cancel') }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="dateFromMenuOk"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="2"
      >
        <v-menu
          ref="dateToMenu"
          v-model="dateToMenu"
          :close-on-content-click="false"
          :return-value.sync="requestParams.dateTo"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="requestParams.dateTo"
              :label="$i18n.t('$l.apicheck.dateTo')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              dense
              class="ma-3"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="requestParams.dateTo"
            no-title
            scrollable
            :locale="$i18n.locale"
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="dateToMenuCancel"
            >
              {{ $t('$l.app.cancel') }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="dateToMenuOk"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        :md="colMd"
      >
        <v-text-field
          v-model="requestParams.subjectSubstring"
          class="ma-3 pa-0 pb-3"
          prepend-icon="mdi-magnify"
          :label="$i18n.t('$l.case.subject')"
          single-line
          hide-details
          dense
          @change="requestParamsChanged"
        />
      </v-col>
      <v-col
        v-if="isAzah"
        cols="12"
        sm="12"
        md="2"
      >
        <v-select
          v-model="requestParams.affiliateVisitPurpose"
          class="ma-3"
          dense
          :disabled="!callResult.finished"
          :loading="!callResult.finished"
          :items="affiliateVisitPurposeItems"
          :label="$i18n.t('$l.api.affiliateVisitPurpose')"
          :item-text="item => item.value !== '' ? $i18n.t(`$l.api.visitReason.${item.value}`) : ''"
          @change="requestParamsChanged"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        :md="colMd"
      >
        <v-select
          v-model="requestParams.checkStatus"
          class="ma-3"
          dense
          :disabled="!callResult.finished"
          :loading="!callResult.finished"
          :items="checkStatusItems"
          :label="$i18n.t('$l.api.checkStatus')"
          :item-text="item => item.value !== '' ? $i18n.t(`$l.api.${item.value}`) : ''"
          :item-value="item => item.value"
          @change="requestParamsChanged"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import DownloadExcelCheckList from '@/components/api/DownloadExcelCheckList.vue'
import DownloadReport from '@/components/api/DownloadReport.vue'

export default {
  name: 'CheckListFilterPanel',
  components: { DownloadExcelCheckList, DownloadReport },
  props: {
    callResult: {
      type: Object,
      required: true
    },
    requestParams: {
      type: Object,
      required: true
    },
    categories: {
      type: Array,
      default: () => [],
      required: false
    }
  },
  data () {
    return {
      apiUserAffiliateItems: [],
      apiUserAffiliatesLoading: true,
      dateFromMenu: false,
      dateToMenu: false,
      affiliateVisitPurposeItems: [
        { value: 'SRVC' },
        { value: 'BAR' },
        { value: 'GAME' },
        { value: '' }
      ]
    }
  },
  computed: {
    isAzah () {
      if (this.categories) { return this.categories.includes('AZAH') } else return false
    },
    colMd () {
      if (this.isAzah) {
        return '2'
      } else {
        return '3'
      }
    },
    checkStatusItems () {
      return [
        { value: 'NEW_OK' },
        { value: 'NEW_MATCH' },
        { value: 'CONFIRMED_OK' },
        { value: 'CONFIRMED_MATCH' },
        { value: 'NEW_UNSEARCHABLE' },
        { value: 'NEW_FAILED' },
        { value: '' }
      ]
    }
  },
  created () {
    if (this.$store.state.user.isAuthenticated) {
      this.loadApiAffiliateItems()
    }
  },
  methods: {
    resetFilterAndSearchOptions () {
      this.requestParams.apiUser = ''
      this.requestParams.apiAffiliate = ''
      this.requestParams.checkStatus = ''
      this.requestParams.dateFrom = ''
      this.requestParams.dateTo = ''
      this.requestParams.affiliateVisitPurpose = ''
      this.requestParams.subjectSubstring = ''
      this.requestParamsChanged()
    },
    dateFromMenuOk () {
      if (this.$refs.dateFromMenu) {
        this.$refs.dateFromMenu.save(this.requestParams.dateFrom)
      }
      this.dateFromMenu = false
      this.requestParamsChanged()
    },
    dateFromMenuCancel () {
      if (this.$refs.dateFromMenu) {
        this.requestParams.dateFrom = ''
        this.$refs.dateFromMenu.save('')
      }
      this.dateFromMenu = false
      this.requestParamsChanged()
    },
    dateToMenuOk () {
      if (this.$refs.dateToMenu) {
        this.$refs.dateToMenu.save(this.requestParams.dateTo)
      }
      this.dateToMenu = false
      this.requestParamsChanged()
    },
    dateToMenuCancel () {
      if (this.$refs.dateToMenu) {
        this.requestParams.dateTo = ''
        this.$refs.dateToMenu.save('')
      }
      this.dateToMenu = false
      this.requestParamsChanged()
    },
    requestParamsChanged () {
      if (this.requestParams.pageOptions.page !== 1) {
        this.requestParams.pageOptions.page = 1
      } else {
        this.$emit('requestParamsChanged')
      }
    },
    loadApiAffiliateItems () {
      this.$xapi.get('apiCheck/getApiAffiliate')
        .then(r => {
          this.apiUserAffiliateItems = r.data
          this.apiUserAffiliateItems.push('')
        })
        .catch(e => {
        })
        .finally(() => {
          this.apiUserAffiliatesLoading = false
        })
    }
  }
}
</script>
