<template>
  <div>
    <v-btn
      text
      :loading="exportInProgress"
      class="info2 white--text"
      @click="confirmationDialog=true"
    >
      {{ $i18n.t('$l.api.export') }}
      <v-icon
        dark
        right
      >
        mdi-file-excel
      </v-icon>
    </v-btn>

    <v-snackbar
      v-model="snackbar"
      color="error"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click.stop="snackbar = false"
        >
          {{ $i18n.t('$l.app.close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <basic-dialog
      v-model="confirmationDialog"
      title="$l.api.export"
      save-button
      save-button-label="$l.app.confirm"
      @close-dialog="confirmationDialog=false"
      @save-dialog="DownloadExcelCheckList()"
    >
      <v-container>
        <div class="font-weight-medium">
          {{ this.$i18n.t('$l.api.CSVConfimationText1') }}
        </div>
        <div v-if="requestParams.apiAffiliate">
          {{ $i18n.t('$l.api.apiUserAffiliate') + ": " + requestParams.apiAffiliate }}
        </div>
        <div v-if="requestParams.dateFrom">
          {{ $i18n.t('$l.apicheck.dateFrom') + ": " + requestParams.dateFrom }}
        </div>
        <div v-if="requestParams.dateTo">
          {{ $i18n.t('$l.apicheck.dateTo') + ": " + requestParams.dateTo }}
        </div>
        <div v-if="requestParams.subjectSubstring">
          {{ $i18n.t('$l.case.subject') + ": " + requestParams.subjectSubstring }}
        </div>
        <div v-if="requestParams.affiliateVisitPurpose">
          {{ $i18n.t('$l.api.affiliateVisitPurposeVisible') + ": " + $i18n.t(`$l.api.visitReason.${requestParams.affiliateVisitPurpose}`) }}
        </div>
        <div v-if="requestParams.checkStatus">
          {{ $i18n.t('$l.api.checkStatus') + ": " + this.$i18n.t(`$l.api.${requestParams.checkStatus}`) }}
        </div>
        <div class="font-weight-medium">
          {{ this.$i18n.t('$l.api.CSVConfimationText2') +" "+ $utils.numberWithSpaces(limit) }}
        </div>
      </v-container>
    </basic-dialog>
  </div>
</template>

<script>
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'

export default {
  name: 'DownloadExcelCheckList',
  props: {
    requestParams: {
      type: Object,
      required: true
    },
    isAzah: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loadCheckListRequest: {
        apiUser: '',
        apiUserPassword: '',
        apiUserRefreshToken: null
      },
      exportInProgress: false,
      snackbar: false,
      snackbarText: this.$i18n.t('$l.api.generatingCSVFailed'),
      vertical: true,
      confirmationDialog: false,
      limit: 200000
    }
  },
  methods: {
    DownloadExcelCheckList () {
      this.confirmationDialog = false
      this.exportInProgress = true
      this.loadCheckListRequest.requestParams = this.requestParams
      const payload = JSON.parse(JSON.stringify(this.loadCheckListRequest))
      payload.requestParams.itemsPerPage = this.limit
      if (payload.requestParams.dateFrom !== '') {
        payload.requestParams.dateFrom = new Date(payload.requestParams.dateFrom + 'T00:00:00').toISOString()
      }
      if (payload.requestParams.dateTo !== '') {
        payload.requestParams.dateTo = new Date(payload.requestParams.dateTo + 'T00:00:00').toISOString()
      }
      this.$xapi.post('apiCheck/checkResult', payload)
        .then(r => {
          this.apiCheckItems = JSON.parse(r.data.data)
          this.createExcelFile(this.apiCheckItems)
        })
        .catch(e => {
          this.snackbar = true
          this.exportInProgress = false
        })
        .finally(() => {
        })
    },
    getApiAffiliatepurpose (affiliateVisitPurpose) {
      if (affiliateVisitPurpose) {
        return this.$i18n.t(`$l.api.visitReason.${affiliateVisitPurpose}`)
      } else {
        return ''
      }
    },
    async createExcelFile (data) {
      // let fileData = '"' + this.$t('$l.api.apiUserAffiliate') + '","' + this.$t('$l.case.created') + '","' + this.$t('$l.api.subject') + '","' + this.$t('$l.api.affiliateVisitSpentAmountVisible') + '","' + this.$t('$l.api.affiliateVisitPurposeVisible') + '","' + this.$t('$l.api.checkStatus') + '"' + '\r\n'
      // data.forEach(item => {
      //   fileData += '"' + item.apiAffiliate + '",'
      //   fileData += '"' + item.created + '",'
      //   fileData += '"' + item.subjectName + '",'
      //   fileData += '"' + item.affiliateVisitSpentAmount + '",'
      //   fileData += '"' + this.getApiAffiliatepurpose(item.affiliateVisitPurpose) + '",'
      //   fileData += '"' + this.$i18n.t(`$l.api.${item.checkStatus}`) + '"' + '\r\n'
      // })
      // const linkSource = 'data:text/plain;charset=utf-8,' + encodeURIComponent(fileData)
      // const downloadLink = document.createElement('a')
      // document.body.appendChild(downloadLink)
      // downloadLink.href = linkSource
      // downloadLink.target = '_self'
      // downloadLink.download = 'okaml_api_check_export.csv'
      // downloadLink.click()

      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Sheet1')
      if (this.isAzah) {
        worksheet.addRow([this.$t('$l.api.apiUserAffiliate'), this.$t('$l.case.created'), this.$t('$l.api.subject'),
          this.$t('$l.api.affiliateVisitSpentAmountVisible'), this.$t('$l.api.affiliateVisitPurposeVisible'), this.$t('$l.api.checkStatus')])
        data.forEach((item) => {
          worksheet.addRow([item.apiAffiliate, item.created, item.subjectName,
            item.affiliateVisitSpentAmount, this.getApiAffiliatepurpose(item.affiliateVisitPurpose), this.$i18n.t(`$l.api.${item.checkStatus}`)])
        })
      } else {
        worksheet.addRow([this.$t('$l.api.apiUserAffiliate'), this.$t('$l.case.created'), this.$t('$l.api.subject'), this.$t('$l.api.checkStatus')])
        data.forEach((item) => {
          worksheet.addRow([item.apiAffiliate, item.created, item.subjectName, this.$i18n.t(`$l.api.${item.checkStatus}`)])
        })
      }
      const blob = await workbook.xlsx.writeBuffer()
      saveAs(new Blob([blob]), 'okaml_api_check_export.xlsx')

      this.exportInProgress = false
    }
  }
}
</script>
