<template>
  <div>
    <v-btn
      text
      :loading="exportInProgress"
      class="info2 white--text"
      @click="confirmationDialog=true"
    >
      {{ $i18n.t('$l.api.reportButton') }}
      <v-icon
        dark
        right
      >
        mdi-file-excel
      </v-icon>
    </v-btn>

    <v-snackbar
      v-model="snackbar"
      color="error"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click.stop="snackbar = false"
        >
          {{ $i18n.t('$l.app.close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <basic-dialog
      v-model="confirmationDialog"
      title="$l.api.reportTitle"
      save-button
      save-button-label="$l.app.confirm"
      @close-dialog="confirmationDialog=false"
      @save-dialog="downloadReport()"
    >
      <v-container>
        <v-row
          dense
        >
          {{ $i18n.t('$l.api.reportText') }}
        </v-row>
        <v-row
          dense
        >
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-menu
              ref="dateFromMenu"
              v-model="dateFromMenu"
              :close-on-content-click="false"
              :return-value.sync="dateFrom"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFrom"
                  :label="$i18n.t('$l.apicheck.dateFrom')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  dense
                  class="ma-3"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="dateFrom"
                no-title
                scrollable
                :locale="$i18n.locale"
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="dateFromMenuCancel"
                >
                  {{ $t('$l.app.cancel') }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="dateFromMenuOk"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-menu
              ref="dateToMenu"
              v-model="dateToMenu"
              :close-on-content-click="false"
              :return-value.sync="dateTo"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateTo"
                  :label="$i18n.t('$l.apicheck.dateTo')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  dense
                  class="ma-3"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="dateTo"
                no-title
                scrollable
                :locale="$i18n.locale"
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="dateToMenuCancel"
                >
                  {{ $t('$l.app.cancel') }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="dateToMenuOk"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-container>
    </basic-dialog>
  </div>
</template>

<script>
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'

export default {
  name: 'DownloadReport',
  data () {
    return {
      confirmationDialog: false,
      exportInProgress: false,
      snackbar: false,
      snackbarText: this.$i18n.t('$l.api.generatingReportFailed'),
      dateFrom: '',
      dateTo: '',
      dateFromMenu: false,
      dateToMenu: false
    }
  },
  methods: {
    dateFromMenuOk () {
      if (this.$refs.dateFromMenu) {
        this.$refs.dateFromMenu.save(this.dateFrom)
      }
      this.dateFromMenu = false
    },
    dateFromMenuCancel () {
      if (this.$refs.dateFromMenu) {
        this.dateFrom = ''
        this.$refs.dateFromMenu.save('')
      }
      this.dateFromMenu = false
    },
    dateToMenuOk () {
      if (this.$refs.dateToMenu) {
        this.$refs.dateToMenu.save(this.dateTo)
      }
      this.dateToMenu = false
    },
    dateToMenuCancel () {
      if (this.$refs.dateToMenu) {
        this.dateTo = ''
        this.$refs.dateToMenu.save('')
      }
      this.dateToMenu = false
    },
    downloadReport () {
      this.confirmationDialog = false
      this.exportInProgress = true
      let params = []
      if (this.dateFrom) {
        params.push('dateFrom=' + new Date(this.dateFrom + 'T00:00:00').toISOString())
      }
      if (this.dateTo) {
        params.push('dateTo=' + new Date(this.dateTo + 'T00:00:00').toISOString())
      }
      this.$xapi.get('apiCheck/reportData?' + params.join('&'))
        .then(r => {
          this.createExcelFile(r.data)
        })
        .catch(e => {
          this.snackbar = true
          this.exportInProgress = false
        })
        .finally(() => {
        })
    },
    async createExcelFile (data) {
      // let fileData = '#' + this.$i18n.t('$l.api.generationPeriod') + ' ' + this.dateFrom + ' - ' + this.dateTo + '\r\n'
      // let reportHeader = []
      // reportHeader.push(this.$i18n.t('$l.api.apiUserAffiliate'))
      // reportHeader.push(this.$i18n.t('$l.api.investigationSum'))
      // reportHeader.push(this.$i18n.t('$l.api.NEW_MATCH'))
      // reportHeader.push(this.$i18n.t('$l.api.CONFIRMED_MATCH'))
      // fileData += reportHeader.join(',') + '\r\n'
      // data.forEach(item => {
      //   fileData += '"' + item.apiaffiliate + '",'
      //   fileData += '"' + item.count + '",'
      //   fileData += '"' + item.newMatchCount + '",'
      //   fileData += '"' + item.confirmedMatchCount + '"' + '\r\n'
      // })
      // const linkSource = 'data:text/plain;charset=utf-8,' + encodeURIComponent(fileData)
      // const downloadLink = document.createElement('a')
      // document.body.appendChild(downloadLink)
      // downloadLink.href = linkSource
      // downloadLink.target = '_self'
      // downloadLink.download = 'okaml_api_check_report.csv'
      // downloadLink.click()

      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('report')
      worksheet.addRow(['#' + this.$i18n.t('$l.api.generationPeriod') + ' ' + this.dateFrom + ' - ' + this.dateTo])
      worksheet.addRow([this.$i18n.t('$l.api.apiUserAffiliate'), this.$i18n.t('$l.api.investigationSum'), this.$i18n.t('$l.api.NEW_MATCH'), this.$i18n.t('$l.api.CONFIRMED_MATCH')])
      data.forEach((item) => {
        worksheet.addRow([item.apiaffiliate, item.allInvestigationsCount, item.newMatchCount, item.confirmedMatchCount])
      })
      const blob = await workbook.xlsx.writeBuffer()
      saveAs(new Blob([blob]), 'okaml_api_check_report.xlsx')

      this.exportInProgress = false
    }
  }
}
</script>
